import { PillStoryblok } from '@/shared/types/storyblok/storyblok-types';
import styles from './Pill.module.scss';
import { storyblokEditable } from '@storyblok/react';
import Link from 'next/link';

const Pill = ({ blok }: PillStoryblok) => {
    const { text, link, symbol } = blok;

    const pillContent = (
        <div className={styles.root} {...storyblokEditable(blok)}>
            {symbol && <div className={styles.symbol}>{symbol}</div>}
            {text}
        </div>
    );

    return link?.story?.full_slug ? (
        <Link style={{ userSelect: 'none' }} href={link.story?.full_slug.replace('home', '')}>
            {pillContent}
        </Link>
    ) : (
        pillContent
    );
};

export default Pill;
